<script>
import ReturnPointsForm from './ReturnPointsForm'

export default {
  extends: ReturnPointsForm,

  created() {
    this.getReturnPoint(this.$route.params.id).then(response => {
      this.form = response
      this.startTime = response.delivery[0] && response.delivery[0].slice(0, -3)
      this.endTime = response.delivery[1] && response.delivery[1].slice(0, -3)
    })
  },

  methods: {
    onSubmit() {
      this.updateReturnPoint(this.form.id, { ...this.form, delivery: this.deliveryTime })
        .then(() => {
          this.successSubmit({ message: 'Точка возврата успешно изменена', routeName: 'return-points' })
        })
        .finally(() => { this.afterSubmit() })
    }
  }
}
</script>
